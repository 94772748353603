import logo from './logo.svg';
import './App.css';
import LebronVideo from "./LeBron James.mp4"
import dunk from "./lbjDunk.gif"

function App() {
  return (
    <div className="App">
      <h1 style={{ marginTop: "-5px", fontFamily: "Lakers", marginLeft: "20%" }}>Lebron on LeSol</h1>
      <div className='nav-div'>
        <ul style={{ listStyle: "none", display: "flex", justifyContent: "center", alignContent: "center" }}>
          <a style={{ backgroundColor: 'black' }} className='nav-link'>HOME</a>
          <a className='nav-link'>CHART</a>
          <a className='nav-link'>BUY</a>
          <a rel="noreferrer" target='_blank' href='https://t.me/+mrnWlOUVO80xODI5' className='nav-link'>TELEGRAM</a>
          <a rel='noreferrer' target='_blank' href='https://twitter.com/ironthroneofbtc' style={{ marginLeft: '5px' }} className='nav-link'>TWITTER</a>
        </ul>
      </div>
      <br />
      <br />
      <div className='main-div'>
        <div className='video-div'>
          <video className='video' autoPlay loop muted>
            <source src={LebronVideo} type='video/mp4' />
          </video>
        </div>
        <br />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "30%" }}>
          <p style={{ fontSize: "28px", fontWeight: "bold" }}>$STANK CA:</p>
          <p style={{ fontSize: '28px', overflowWrap: 'anywhere', fontWeight: "bold", marginTop: '-10px' }}>2nQDC6cUTuv6ZaUFxyfVzaJ9SCpMUsXBmaSGQU2MH2aB</p>
          <p>In the pantheon of basketball greatness, one name stands out with an unwavering spotlight:</p>
          <h1 style={{ cursor: "default" }}>LeBron on LeSol $STANK</h1>
          <p>This is a community driven coin that we hope will bring lots of Lebron, and basketball fans together. The LeBron on LeSol coin <span style={{ fontWeight: "bold" }}>$STANK</span>, is destined for greatness!</p>
          <p>We hope to see you join us in making this one of the greatest meme coins to ever exist!  This coin isn't just a cryptocurrency; it's a symbol of excellence, a tribute to a legacy that transcends boundaries and a testament to the indomitable spirit of one of the greatest athletes the world has ever witnessed. But most importantly…</p>
          <h1 style={{ cursor: "default" }}>THAT SH*T STANK DON'T IT</h1>
          <br />
          <button><a style={{ textDecoration: "none", color: 'white' }} rel="noreferrer" target='_blank' href='https://t.me/+mrnWlOUVO80xODI5'>Connect with us!</a></button>
          <br />
        </div>
      </div>
    </div>
  );
}

export default App;
